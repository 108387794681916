export const environment = {
  production: true,
  portalDomain: "https://onepass.cic.hk/",
  apiDomain: "https://onepass.cic.hk/api/",
  companyGroupId: "oty8izdibnzYNAaKy697",
  oktaDomain: "onepass-sso.cic.hk",
  idpReturnAppId: "0oa8m33chjQ7NbLvH697",
  oktaAuthAppId: "0oa8m4ch8lv9BsmZY697",
  oktaAuthAppIdHighRisk: "0oa8m4ch8lv9BsmZY697",
  oktaAuthAppIdLowRisk: "0oa8m4ch8lv9BsmZY697",
  oktaClientId: "0oa8m1rjddUUcSadg697",
  oktaClientIdHighRisk: "0oa8m1rjddUUcSadg697",
  oktaClientIdLowRisk: "0oa8m1rjddUUcSadg697",
  plugins: [],
};
